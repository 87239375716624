import { Building, BuildingWithCapacity } from "interfaces/Building";
import { toJS } from "mobx";

/**
 * Check if the given building has space available
 * availableCapacity is either adjusted_capacity OR total_capacity
 * @param building - the building
 * @returns false if no building, or bookedSpaces >= availableCapacity
 */

// Duplicated hasBookableSpace function to include date parameter for Bookable Space capacity check

export function hasBookableSpaceCapacity(building: BuildingWithCapacity | null, theDate: string) {
  console.log("Inside hasBookableSpaceCapacity");
  if (!building) return false;

  if (building && building.adjusted_capacity === 0) return false;

  const availableCapacity = getAvailableCapacity(
    building.adjusted_capacity,
    building.total_capacity
  );
  console.log("AvailableCapacity :", availableCapacity);
  console.log("Building object date", building.date);
  console.log("Booking Date :", theDate);

  const currentlyBookedSpaces = building.booked_attendance || 0;

  if (theDate == building.date) {
    console.log("Inside IF.....");
    console.log("Currently Booked Spaces :", building.booked_attendance);
    const hasBookableSpaces = currentlyBookedSpaces < availableCapacity;
    console.log("currentlyBookedSpaces < availableCapacity? ", hasBookableSpaces);
    return hasBookableSpaces;
  } else {
    console.log("Inside ELSE.....");
    return true;
  }
}

export function hasBookableSpace(building: BuildingWithCapacity | null) {
  if (!building) return false;
  if (building && building.adjusted_capacity === 0) return false;
  const availableCapacity = getAvailableCapacity(
    building.adjusted_capacity,
    building.total_capacity
  );
  const currentlyBookedSpaces = building.booked_attendance || 0;
  const hasBookableSpaces = currentlyBookedSpaces < availableCapacity;
  return hasBookableSpaces;
}

/**
 * work out the available capacity based on the given adjusted capacity
 * @param adjustedCapacityPercentage
 * @param total_capacity
 * @returns 0 if total_capacity undefined
 * 0 if adjusted_capacity percentage = 0
 * total_capacity if adjusted_capacity = 100 or undefined
 * adjusted_capacity as % of total_capacity
 */
export function getAvailableCapacity(
  adjustedCapacityPercentage: number | null | undefined,
  total_capacity: number | undefined
) {
  if (!total_capacity) return 0;
  if (adjustedCapacityPercentage === 0) return 0;
  if (!adjustedCapacityPercentage) return total_capacity;
  if (adjustedCapacityPercentage === 100) return total_capacity;
  return total_capacity;
}

export function cityAbbr(
  building: Building | Partial<Building> | BuildingWithCapacity | null
): string {
  if (!building) {
    return "";
  }
  return getCityAbbr(building);
}
export const getCityAbbr = (building: Building | Partial<Building> | null): string => {
  /**
   * Takes a Building object and evaluates the Buildings Serraview ID against a list of known Serraview IDs for Active Buildings.
   * If the ID is not known to us (i.e. a new site/office is added), default to using the city as a three letter abbreviation until the new ID is added in code.
   *
   * @param {Building}
   * @return {string}
   */
  if (!building) {
    return "";
  }
  switch (building.serraview_id) {
    case 5:
      return "PER";
    case 6:
      return "MEL";
    case 7:
      return "BNE";
    case 8:
      return "ADL";
    case 25:
      return "SIN";
    case 26:
      return "GUR";
    case 27:
      return "SHA";
    case 28:
      return "TYO";
    case 29:
      return "KUL";
    case 30:
      return "ADL";
    case 32:
      return "MAN";
    case 33:
      return "BNE";
    case 34:
      return "KUL";
    case 35:
      return "MAN";
    case 37:
      return "SIN";
    case 38:
      return "HOU";
    case 39:
      return "HOU";
    case 40:
      return "WAS";
    case 42:
      return "SCL";
    case 43:
      return "SCL";
    case 47:
      return "FED";
    case 48:
      return "LON";
    case 49:
      return "MEL";
    case 50:
      return "ADL";
    case 51:
      return "SAS";
    case 52:
      return "TRI";
    case 55:
      return "IQU";
    case 56:
      return "HOU";
    case 57:
      return "SCL";
    case 59:
      return "BNE";
    case 61:
      return "BNE";
    case 70:
      return "TOR";
    case 71:
      return "QUI";
    case 72:
      return "LIM";
    case 73:
      return "BHO";
    case 74:
      return "STJ";
    case 75:
      return "TUC";
    case 76:
      return "BHO";
    case 77:
      return "ANF";
    case 78:
      return "OLP";
    case 79:
      return "RDV";
    case 80:
      return "RMG";
    case 81:
      return "RES";
    case 82:
      return "BWM";
    case 83:
      return "BRM";
    case 84:
      return "CVM";
    case 85:
      return "DNM";
    case 86:
      return "GRM";
    case 87:
      return "HPP";
    case 89:
      return "PDM";
    case 90:
      return "POM";
    case 91:
      return "PHE";
    case 92:
      return "SAM";

    // Append Extra Serraview ID's here as they become available
    default:
      return building?.city ? building.city.toUpperCase().substring(0, 3) : "";
  }
};

export const getOfficeServiceIframe = (): string => {
  return "https://teams.apptreeio.com/team/cw_BHP_prod";
};

export const getOfficeIframe = (building: Building): string => {
  switch (building.serraview_id) {
    case 50:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-Adelaide!.aspx";
    case 76:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Belo-Horizonte.aspx";
    case 42:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Santiago-CEP6000.aspx";
    case 51:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Saskatoon-corporate-office.aspx";
    case 70:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Toronto-corporate-office.aspx";
    case 26:
      return "https://spo.bhpbilliton.com/:u:/r/sites/globalproperty/SitePages/Gurgaon-corporate-office.aspx";
    case 29:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-KL!.aspx?CT=1689662917916&OR=OWA-NT&CID=fb5ba970-5128-776a-3a11-8f8aae3a1575";
    case 35:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-Manila!.aspx?CT=1689663139648&OR=OWA-NT&CID=fabe3c02-6a81-3eab-27f0-ba17c39da65d";
    case 27:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Shanghai-corporate-office.aspx";
    case 25:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-Singapore!.aspx?CT=1689663219479&OR=OWA-NTB&CID=aa5e28ff-c7ea-e81c-7f0c-5805c47fbbad";
    case 28:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-Tokyo!.aspx?CT=1689663631978&OR=OWA-NTB&CID=dc8b35a3-0423-2958-7644-ffe7778a9107";
    case 7:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome%20to%20Brisbane.aspx";
    case 61:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome%20to%20Brisbane.aspx";
    case 49:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-Melbourne!.aspx";
    case 6:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-Melbourne!.aspx";
    case 5:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-Perth!.aspx";
    case 48:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-London!(1).aspx?CT=1685499709781&OR=OWA-NT&CID=1b9e6017-94bb-f0da-6ded-3be56b54b2a9";
    case 34:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-KL!.aspx?CT=1689662917916&OR=OWA-NT&CID=fb5ba970-5128-776a-3a11-8f8aae3a1575";
    case 37:
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Welcome-to-Singapore!.aspx?CT=1689663219479&OR=OWA-NTB&CID=aa5e28ff-c7ea-e81c-7f0c-5805c47fbbad";
    default:
      return "";
  }
};

export const workSpaceIframe = (building: Building): string => {
  switch (building.serraview_id) {
    // case 5:
    //   return "https://workspaces.dnaspaces.io/?token=ee0080b6-0903-4982-b272-d380aadd1014#/";
    case 136:
      return "https://workspaces.dnaspaces.io/?token=53c172d8-660c-4867-bece-0a41807c6f5b&src=dnas-signage&tmp=1731559777018#/";

    default:
      return "";
  }
};
