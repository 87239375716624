// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { makeAutoObservable, flow, toJS, runInAction } from "mobx";
import RootStore from "./RootStore";
import client from "services/api";
import {
  Building,
  BuildingType,
  BuildingWithCapacity,
  CalibrationDetail,
  DEFAULT_OFFSET_X,
  DEFAULT_OFFSET_Y,
  DEFAULT_SCALE_X,
  DEFAULT_SCALE_Y,
  DEFAULT_SPACE_RADIUS,
  DEFAULT_YRATIO,
  Floor,
  floorplanSpecs,
  Space,
  SpaceBooking,
  // BuildingList,
} from "interfaces/Building";
import { set } from "lodash";
import { BuildingTeamSchedule } from "interfaces/Schedule";
import moment from "moment";
import { dateToUTCRange } from "interfaces/Utils";
import { BasicBooking, BookingJSON, Period } from "utils/hourlyBookings";
import { BluetoothDisabled } from "@material-ui/icons";
import { deskTypeIds, sampleSpaces } from "components/booking/enhancedFloorplan/OtherTypes";

const defaultFloor: Floor | null = null;
const availableFloorPlans: number[] = [
  8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 36,
  37, 38, 39, 40, 43, 44, 45, 46, 47, 48, 66, 67, 68, 84, 85, 86, 87, 89, 90, 91, 92, 93, 95, 96,
  139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 153, 154, 155, 156, 157, 158, 159, 160, 161,
  162, 163, 164, 165, 168, 169, 170, 174, 178, 179, 180, 181, 182, 184, 189, 191, 192, 199, 200,
  202,
];

export default class BuildingStore {
  bwcTotalRecordCount: number = 0;
  isLoadingBuildingCapacity: number[] = [];
  isLoadingDefaultUsers: number[] = [];
  hasLoadedDefaultUsers: { buildingId: number; totalCapcity: number }[] = [];
  buildingsWithCapacity: BuildingWithCapacity[] = [];
  buildingCapacityRange: BuildingWithCapacity[] = [];
  buildingCapacityFiltered: BuildingWithCapacity[] = [];
  // buildingList: BuildingList[] = [];
  locations: Building[] = [];
  teamLocations: Building[] = [];
  buildingTeamSchedule: BuildingTeamSchedule[] | null = null;
  teamBuildingSchedules: { buildingId: number; buildingTeamSchedule: BuildingTeamSchedule[] }[] =
    [];
  spaces: Space[] = [];
  enhancedSpaces: Space[] = [];
  floorSpaces: { [floorId: string]: Space[] } = {};
  floorEnhancedSpaces: { [floorId: string]: Space[] } = {};
  currentOffice: BuildingWithCapacity | null = null;
  currentBuilding: BuildingWithCapacity | null = null;
  currentFloor: Floor | null = null;
  currentSpace: Space | null = null;

  // added this for V2 interactive floorplan
  // didn't want to stuff legacy use of current data
  currentFloorId: number | null = null;
  focussedSpaceId: number | null = null;

  showFloorPlan: boolean = false;
  floorplan: {
    building_id?: number;
    floor_id?: number;
    floor_name?: string;
    floor_hasFloorPlan?: boolean;
    space_id?: number;
    space_name?: string;
    serraview_id?: number;
    date?: string;
    canEdit?: boolean;
    showLabels?: boolean;
  } = {};

  isLoading = false;
  isLoadingSpaces = false;
  floorsLoadingSpaces: number[] = [];
  isLoadingCapacity = false;
  isLoadingFloorPlan = false;
  error: string | unknown = "";
  rootStore!: RootStore;
  buildings: Building[] = [];
  isDemo = true;

  isLoadingFavourites = false;

  spaceStatus: { [spaceId: number]: SpaceBooking[] } = {};

  /**
   * Get a date range for a given date AT the building
   * uses the timezone to establish fully qualified javascript start/end Dates
   * @param buildingStore - the rootStore (needs buildingStore)
   * @param buildingId - the building id
   * @param date - the date AT the building YYYY-MM-DD
   * @returns an object containing fully qualified javascript dates {start, end}
   */
  buildingDateToUTCRange = flow(function* (
    this: BuildingStore,
    buildingId: number,
    date: string,
    from: string = "00:00:00",
    to: string = "23:59:59"
  ) {
    const theBuilding = this.getBuilding(buildingId);
    if (!theBuilding) return null;
    const timezone = theBuilding?.building_timezone;
    if (!timezone) return null;

    const momentFrom = moment.tz(`${date} ${from}`, timezone);
    const momentTo = moment.tz(`${date} ${to}`, timezone);
    const periodFrom = new Date(momentFrom.toDate());
    const periodTo = new Date(momentTo.toDate());
    const thisPeriod = { start: periodFrom, end: periodTo };
    return thisPeriod;
  });

  spaceIsBooked(spaceId: number, timeFromUTC: Date, timeToUTC: Date) {
    const spaceStatus = this.spaceStatus[spaceId];
    const isBooked = spaceStatus
      ? spaceStatus.find((ss) => ss.UTCTimeTo < timeFromUTC && ss.UTCTimeFrom > timeToUTC)
      : null;
    console.log("sib: ", spaceId, spaceStatus, isBooked);
    return isBooked?.user_id !== null || false;
  }

  getSpaceBookingsInPeriod = flow(function* (this: BuildingStore, spaceId, period: Period) {
    const res = yield client.get(
      `/api/bookings?space=${spaceId}&within_start_datetime_utc=${period.start.toUTCString()}&within_end_datetime_utc=${period.end.toUTCString()}`
    );
    let bookings: BasicBooking[] = [];
    if (res && res.data && res.data.results) {
      bookings = res.data.results.map((booking: BookingJSON) => {
        const thisBooking: BasicBooking = {
          id: booking.id,
          space: booking.space,
          user_name: booking.user?.preferred_name ? booking.user!.preferred_name : `Not available`,
          user_id: booking.user?.id || null,
          booking_period: {
            start: new Date(booking.start_datetime_utc),
            end: new Date(booking.end_datetime_utc),
          },
        };
        return thisBooking;
      });
    }
    console.log("res: ", period, toJS(bookings));
    return bookings;
  });

  getSpaceBookings = flow(function* (
    this: BuildingStore,
    spaceId: number | undefined,
    date: string,
    timezone: string
  ) {
    if (!spaceId) return;
    try {
      this.isLoadingFavourites = true;
      const utcRange = dateToUTCRange(timezone, date, "00:01:00", "23:58:00");
      const res = yield client.get(`/api/bookings/?space=${spaceId}`);
      let bookings: SpaceBooking[] = [];
      if (res && res.data && res.data.results) {
        res.data.results.map((booking) => {
          if (booking.user_id || booking.is_booked) {
            const thisBooking: SpaceBooking = {
              sv_id: spaceId,
              date: date,
              UTCTimeFrom: utcRange.start,
              UTCTimeTo: utcRange.end,
              user_id: booking.user_id,
            };
            bookings.push(thisBooking);
          }
        });
      }
      return bookings;
    } catch (err) {
      console.log(err);
      this.error = err;
    }
  });

  buildingHasFavourites(buildingId: number) {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  v2FloorplanBuildings: number[] = [
    1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 18, 19, 22, 23, 24, 25, 27, 31, 32, 33, 34, 37, 38,
  ];

  isV2Floorplan() {
    return true;
  }

  isLoadingSpacesForThisFloor(floorId: number | null) {
    if (!floorId) return false;
    return this.floorsLoadingSpaces.includes(floorId);
  }

  getHardCodedFloorplanSpecs(buildingId?: number) {
    const bldSpecs = floorplanSpecs.find((fps) => fps.buildingId === buildingId);
    if (bldSpecs) return bldSpecs.floorplanSpecs;
    else
      return {
        scale: { x: DEFAULT_SCALE_X, y: DEFAULT_SCALE_Y },
        offset: { x: DEFAULT_OFFSET_X, y: DEFAULT_OFFSET_Y },
        spaceRadius: DEFAULT_SPACE_RADIUS,
        yRatio: DEFAULT_YRATIO,
      };
  }

  openFloorPlan(status: boolean) {
    runInAction(() => {
      this.showFloorPlan = status;
    });
  }

  setupFloorPlanFromSerraviewId = flow(function* (
    this: BuildingStore,
    serraview_id: number,
    canEdit: boolean,
    date: string = moment(new Date()).format("YYYY-MM-DD")
  ) {
    this.isLoadingFloorPlan = true;
    try {
      this.floorplan = {};
      const res = yield client.get(`/api/spaces/${serraview_id}/`);
      const theSpace = res.data;
      runInAction(() => {
        this.floorplan.building_id = theSpace.building_id;
        this.floorplan.serraview_id = serraview_id;
        this.floorplan.floor_id = theSpace.floor_id;
        this.floorplan.date = date;
        this.floorplan.canEdit = canEdit;
        let floorName = "";
        let hasBackendImage = false;
        this.loadBuilding(this.floorplan.building_id || null).then((bld) => {
          if (bld.floors) {
            const theFloor = bld.floors.find(
              (flr: { id: number; name: string; floor_plan: string }) =>
                flr.id === this.floorplan.floor_id
            );
            floorName = theFloor.name;
            hasBackendImage = theFloor.floor_plan && theFloor.floor_plan != "" ? true : false;
            this.floorplan.floor_hasFloorPlan = hasBackendImage
              ? true
              : this.floorplan.floor_id
              ? availableFloorPlans.includes(this.floorplan.floor_id)
              : false;
            if (floorName !== "") this.floorplan.floor_name = floorName;
          }
        });
        this.floorplan.space_id = theSpace.id;
        this.floorplan.space_name = theSpace.name;
      });
      this.setCurrentBuilding(theSpace.building_id);
      this.setCurrentFloor(theSpace.floor_id);
      this.setCurrentSpace(theSpace.floor_id, theSpace.id);
      this.setFocussedSpaceId(theSpace.id);
      this.loadSpaces(theSpace.building_id, theSpace.floor_id, date, true);
      this.openFloorPlan(true);
      this.isLoadingFloorPlan = false;
      return theSpace;
    } catch (err) {
      this.isLoadingFloorPlan = false;
      this.error = err;
    }
  });

  setupFloorPlanFromBookingDetails = (
    building: Building | null,
    floor: Floor | null,
    space: Space | null,
    canEdit: boolean,
    date: string = moment(new Date()).format("YYYY-MM-DD"),
    showLabels: boolean = false
  ) => {
    if (!floor) return;
    this.isLoadingFloorPlan = true;
    try {
      runInAction(() => {
        this.floorplan.building_id = building ? building.id : undefined;
        this.floorplan.serraview_id = undefined;
        this.floorplan.floor_id = floor.id;
        this.floorplan.floor_name = floor.name;
        this.floorplan.date = date;
        this.floorplan.canEdit = canEdit;
        this.floorplan.showLabels = showLabels;
        this.floorplan.floor_hasFloorPlan =
          floor.floor_plan && floor.floor_plan !== ""
            ? true
            : this.floorplan.floor_id
            ? availableFloorPlans.includes(this.floorplan.floor_id)
            : false;
        this.floorplan.space_name = space?.name;
        if (building && floor) {
          this.loadSpaces(building.id, floor.id, date, true);
          this.setCurrentBuilding(building.id);
          this.setCurrentFloor(floor.id);
          if (space) {
            this.setCurrentSpace(floor.id, space.id);
            this.setFocussedSpaceId(space.id);
          }
        }
      });
      this.isLoadingFloorPlan = false;
    } catch (err) {
      this.isLoadingFloorPlan = false;
      this.error = err;
    }
  };

  loadBuilding = flow(function* (this: BuildingStore, buildingId: number | null) {
    if (buildingId === null) return "";
    this.isLoading = true;
    try {
      const res = yield client.get(`/api/building/buildings/${buildingId}/`);
      this.isLoading = false;
      return res.data;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
    }
  });

  setFilteredList(country: string, searchTerm: string) {
    let results =
      country && country !== ""
        ? [...this.buildingsWithCapacity.filter((bld) => bld.country === country)]
        : [...this.buildingsWithCapacity];
    if (searchTerm !== "") {
      results = results.filter((bld) => {
        return (
          bld.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
          bld.building_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
    this.buildingCapacityFiltered = [...results];
  }

  setCurrentBuilding(
    buildingId: number | null,
    date: string = moment(new Date()).format("YYYY-MM-DD")
  ) {
    if (`${buildingId}` === `${this.currentBuilding?.id}`) return;
    const preLoadedBuilding =
      this.buildingsWithCapacity.find((bld) => bld.id === Number(buildingId)) || null;
    if (preLoadedBuilding) {
      runInAction(() => {
        this.currentBuilding = preLoadedBuilding;
        this.spaces = [];
        this.setCurrentFloor(null);
      });
    } else {
      this.loadBuildingCapacity(Number(buildingId), date).then(() => {
        runInAction(() => {
          this.currentBuilding =
            this.buildingsWithCapacity.find((bld) => bld.id === Number(buildingId)) || null;
          this.spaces = [];
          this.setCurrentFloor(null);
        });
      });
    }
  }

  setCurrentOffice(buildingId: number | null) {
    runInAction(() => {
      this.currentOffice = buildingId ? this.getBuilding(buildingId) : null;
    });
  }

  setCurrentFloorId(floorId: number | null) {
    runInAction(() => {
      this.currentFloorId = floorId;
    });
  }

  setCurrentFloor(floorId: number | null) {
    runInAction(() => {
      this.spaces =
        this.currentBuilding && floorId
          ? this.getSpaces(this.currentBuilding.id, floorId) || []
          : [];
      if (this.currentBuilding !== null) {
        this.currentFloorId = floorId;
        this.currentFloor =
          floorId !== null
            ? this.currentBuilding.floors?.find((flr) => flr.id === Number(floorId)) || null
            : defaultFloor;
        if (this.currentFloor)
          this.currentFloor.hasFloorPlan =
            floorId !== null ? availableFloorPlans.indexOf(floorId) > -1 : false;
      }
      if (this.focussedSpaceId && this.spaces && this.spaces.length > 0) {
        const foundSpace = this.spaces.filter((spc) => spc.id === this.focussedSpaceId);
        const onThisFloor = foundSpace && foundSpace.length > 0;
        if (!onThisFloor) this.setFocussedSpaceId(null);
      }
    });
    this.setCurrentSpace(floorId, null);
  }

  setCurrentSpace(floorId: number | null, spaceId: number | null, space?: Space) {
    runInAction(() => {
      if (space) {
        this.currentSpace = { ...space };
      } else {
        if (floorId == null || spaceId == null) {
          this.currentSpace = null;
        } else {
          this.spaces.map((spc) => {
            if (spc.id === spaceId) {
              this.currentSpace = { ...spc };
            }
          });
        }
      }
    });
  }

  setFocussedSpaceId(spaceId: number | null) {
    runInAction(() => {
      this.focussedSpaceId = spaceId;
    });
  }

  isOffice(buildingId: number | null) {
    const theBuilding = buildingId
      ? this.buildingsWithCapacity.find((bld) => bld.id === Number(buildingId))
      : null;
    return theBuilding && theBuilding.building_type !== BuildingType.SITE;
  }

  // this should be getBuilding but somehow getBuilding looks at capacity
  getBuildingSimple(buildingId: number): Building | null {
    const theBuilding = this.buildings.find((bld) => bld.id === buildingId) || null;
    if (!theBuilding) {
      this.loadBuilding(buildingId).then((res) => {
        return res.data;
      });
    }
    return theBuilding;
  }

  getBuilding(buildingId: number, date: string = moment(new Date()).format("YYYY-MM-DD")) {
    const theBuilding = this.buildingsWithCapacity.find((bld) => bld.id === Number(buildingId));
    if (!buildingId) return null;
    if (!this.isLoadingCapacity) {
      if (!theBuilding)
        this.loadBuildingWithCapacity(buildingId, date, date).then((blds) => {
          return blds ? blds[0] : null;
        });
      // if (theBuilding && theBuilding.total_capacity === 0) {
      //   this.getCapacityFromDefaultUsers(buildingId).then((res) => {
      //     set(theBuilding, "total_capacity", res);
      //     return theBuilding;
      //   });
      // }
    }
    return theBuilding || null;
  }

  getBasicBuilding(buildingId: number) {
    return this.buildings.find((bld) => bld.id === buildingId) || null;
  }

  getCapacityFromDefaultUsers = flow(function* (this: BuildingStore, buildingId: number) {
    const previouslyFound = this.hasLoadedDefaultUsers.find((bld) => bld.buildingId === buildingId);
    if (previouslyFound) return previouslyFound.totalCapcity;
    this.isLoading = true;
    try {
      if (!this.isLoadingDefaultUsers.includes(buildingId)) {
        this.isLoadingDefaultUsers.push(buildingId);
        const res = yield client.get(`/api/users-simple/?profile__default_office=${buildingId}`);
        this.isLoading = false;
        this.hasLoadedDefaultUsers.push({
          buildingId: buildingId,
          totalCapcity: res?.data.count || 0,
        });
        return res?.data.count;
      }
    } catch (err) {
      this.error = err;
      this.isLoading = false;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  loadBuildingsWithCapacity = flow(function* (
    this: BuildingStore,
    date: string,
    forceLoad: boolean = false
  ) {
    this.isLoading = true;
    this.isLoadingCapacity = true;
    if (this.buildingsWithCapacity.length > 4 && !forceLoad) {
      const dateInList = this.buildingsWithCapacity[0].date;
      if (moment(dateInList).diff(moment(new Date()), "days") == 0) {
        // already have a list of buildings with capacity on this day, so don't reload
        this.isLoading = false;
        this.isLoadingCapacity = false;
        return;
      }
    }

    try {
      const res = yield client.get(`/api/building/buildings/date/${date}/capacity/`, {
        timeout: 0,
      });
      this.bwcTotalRecordCount = res.data.count;
      this.buildingsWithCapacity = [...res.data.results].map((bld) => ({
        ...bld,
        floors: [
          ...bld.floors.map((flr: Floor) => ({
            ...flr,
            spaces: [],
          })),
        ],
      }));
      this.buildingsWithCapacity.sort((a, b) => (a.building_name < b.building_name ? -1 : 1));
      this.isLoading = false;
      this.isLoadingCapacity = false;
    } catch (err) {
      this.error = err;
      this.isLoading = false;
      this.isLoadingCapacity = false;
    }
  });

  // loadBuildingsList = flow(function* (this: BuildingStore, forceLoad: boolean = false) {
  //   this.isLoading = true;
  //   this.isLoadingCapacity = true;
  //   if (this.buildingList.length > 4 && !forceLoad) {
  //     this.isLoading = false;
  //     this.isLoadingCapacity = false;
  //     return;
  //   }

  //   try {
  //     const res = yield client.get(`/api/building/buildingslist/`, {
  //       timeout: 0,
  //     });
  //     this.bwcTotalRecordCount = res.data.count;
  //     this.buildingList = [...res.data.results].map((bld) => ({
  //       ...bld,
  //       floors: [
  //         ...bld.floors.map((flr: Floor) => ({
  //           ...flr,
  //           spaces: [],
  //         })),
  //       ],
  //     }));
  //     this.buildingList.sort((a, b) => (a.building_name < b.building_name ? -1 : 1));
  //     this.isLoading = false;
  //     this.isLoadingCapacity = false;
  //   } catch (err) {
  //     this.error = err;
  //     this.isLoading = false;
  //     this.isLoadingCapacity = false;
  //   }
  // });

  /**
   * Deprecated in favour of loadBuildingSchedule ?team_id={teamId}
   */
  loadBuildingTeamSchedule = flow(function* (
    this: BuildingStore,
    buildingId: number,
    teamId: number,
    startDate: string,
    endDate: string
  ) {
    this.isLoading = true;
    try {
      const res = yield client.get(
        `/api/building/${buildingId}/team/${teamId}/schedule/${startDate}/${endDate}/`
      );
      const bts: BuildingTeamSchedule[] = [...res.data.results];
      bts.map((ts) => {
        console.log("asdhjfkhajfdhjkahdfhahdfjkhakjdfhfkjadhfkjahdf", JSON.stringify(ts));
      });
      this.buildingTeamSchedule = bts.map((ts, idx) => ({
        ...ts,
        users: ts.users.map((us) => ({
          ...us,
          default_team_id: ts.team.id,
          default_team_name: ts.team.name,
          default_team_role: "unknown",
        })),
      }));
      this.isLoading = false;
    } catch (err) {
      this.error = err;
    }
    this.isLoading = false;
  });

  loadBuildingSchedule = flow(function* (
    this: BuildingStore,
    buildingId: number,
    teamId: number | null,
    startDate: string,
    endDate: string
  ) {
    this.isLoading = true;
    try {
      const url = teamId
        ? `/api/building/${buildingId}/schedule/${startDate}/${endDate}/?team_id=${teamId}`
        : `/api/building/${buildingId}/schedule/${startDate}/${endDate}/`;
      const res = yield client.get(url);
      const bts: BuildingTeamSchedule[] = [...res.data.results];
      this.buildingTeamSchedule = bts.map((ts, idx) => ({
        ...ts,
        users: ts.users.map((us) => ({
          ...us,
        })),
      }));
      this.isLoading = false;
    } catch (err) {
      this.error = err;
    }
    this.isLoading = false;
  });

  getBuildingTeamSchedule = flow(function* (
    this: BuildingStore,
    buildingId: number,
    teamId: number,
    startDate: string,
    endDate: string
  ) {
    this.isLoading = true;
    try {
      const res = yield client.get(
        `/api/building/${buildingId}/team/${teamId}/schedule/${startDate}/${endDate}/`
      );
      const bts: BuildingTeamSchedule[] = [...res.data.results];
      this.isLoading = false;
      return bts;
    } catch (err) {
      this.error = err;
    }
    this.isLoading = false;
  });

  loadTeamBuildingSchedules = (
    buildingIds: number[],
    teamId: number,
    startDate: string,
    endDate: string
  ) => {
    this.isLoading = true;
    try {
      this.teamBuildingSchedules = [];
      const res = buildingIds.map((buildingId) => {
        this.getBuildingTeamSchedule(buildingId, teamId, startDate, endDate).then((res) => {
          const theSchedule = { buildingId: buildingId, buildingTeamSchedule: [...(res || [])] };
          this.teamBuildingSchedules.push(theSchedule);
        });
      });
      this.isLoading = false;
    } catch (err) {
      this.error = err;
    }
    this.isLoading = false;
  };

  loadBuildingWithCapacity = flow(function* (
    this: BuildingStore,
    buildingId: number,
    startDate: string,
    endDate: string
  ) {
    if (this.isLoadingBuildingCapacity.includes(buildingId)) return;
    this.isLoadingBuildingCapacity.push(buildingId);
    this.isLoading = true;
    try {
      const res = yield client.get(
        `/api/building/buildings/${buildingId}/${startDate}/${endDate}/capacity/`
      );
      const buildingSequence: BuildingWithCapacity[] = [...res.data];
      this.buildingCapacityRange = buildingSequence.map((capacity, idx) => {
        if (
          !this.buildingsWithCapacity.find(
            (bld) => bld.id === capacity.id && bld.date === capacity.date
          )
        )
          this.buildingsWithCapacity.push({
            ...capacity,
            display_name: capacity.display_name || `${capacity.city}, ${capacity.building_name}`,
            floors: capacity.floors
              ? [
                  ...capacity.floors.map((flr: Floor) => ({
                    ...flr,
                    spaces: [],
                  })),
                ]
              : [],
          });

        return {
          ...capacity,
        };
      });
      this.isLoadingBuildingCapacity.splice(this.isLoadingBuildingCapacity.indexOf(buildingId), 1);
      this.isLoading = false;
      return buildingSequence;
    } catch (err) {
      this.error = err;
    }
    this.isLoading = false;
  });

  loadBuildingCapacity = flow(function* (this: BuildingStore, buildingId: number, theDate: string) {
    this.isLoading = true;
    try {
      const res = yield client.get(
        `/api/building/buildings/${buildingId}/${theDate}/${theDate}/capacity/`
      );
      const buildingSequence: BuildingWithCapacity[] = [...res.data];
      console.log("buildingSequence....", buildingSequence);
      if (
        buildingSequence &&
        buildingSequence[0].floors &&
        !this.buildingsWithCapacity.find((bld) => bld.id === Number(buildingId))
      ) {
        this.buildingsWithCapacity.push({
          ...buildingSequence[0],
          floors: [
            ...buildingSequence[0].floors.map((flr: Floor) => ({
              ...flr,
              spaces: [],
            })),
          ],
        });
        this.buildingsWithCapacity.sort((a, b) => (a.building_name < b.building_name ? -1 : 1));
        this.isLoading = false;
      }
      return buildingSequence;
    } catch (err) {
      this.error = err;
    }
    this.isLoading = false;
  });

  loadBuildings = flow(function* (this: BuildingStore) {
    // This never changes and the whole list is in memory - we can cache
    if (this.buildings.length > 4) return;

    this.isLoading = true;
    try {
      const res = yield client.get("/api/building/buildings/", {
        timeout: 0,
      });
      this.buildings = res.data.results.map((bld: Building) => ({
        ...bld,
        display_name: bld.display_name ? bld.display_name : `${bld.city} ${bld.building_name}`,
      }));
      this.buildings.sort((a, b) => (a.building_name < b.building_name ? -1 : 1));
    } catch (err) {
      this.error = err;
    }
    this.isLoading = false;
  });

  primeLoadCapacity = flow(function* (this: BuildingStore) {
    this.isLoading = true;
    try {
      const res = yield client.get("/api/building/buildings/");
      this.buildingsWithCapacity = res.data.results.map((bld: BuildingWithCapacity) => ({
        ...bld,
        display_name: bld.display_name ? bld.display_name : `${bld.city} ${bld.building_name}`,
        floors: bld.floors?.map((flr) => ({
          ...flr,
          hasFloorPlan: availableFloorPlans.indexOf(flr.id) > -1,
        })),
      }));
      this.buildingsWithCapacity.sort((a, b) => (a.building_name < b.building_name ? -1 : 1));
    } catch (err) {
      this.error = err;
    }
    this.isLoading = false;
    this.isLoadingCapacity = false;
  });

  loadTeamBuildings = flow(function* (this: BuildingStore, teamId: number) {
    const theUrl = `/api/building/buildings/?userprofile__team=${teamId.toString()}`;
    if (teamId) {
      this.isLoading = true;
      try {
        const res = yield client.get(theUrl);
        this.teamLocations = [...res.data.results];
        this.teamLocations.sort((a, b) => (a.city < b.city ? -1 : 1));
        this.isLoading = false;
      } catch (err) {
        this.error = err;
      }
      this.isLoading = false;
    }
  });

  getCapacityList(this: BuildingStore, t: any): { value: string; label: string }[] {
    const numberOfDecimals = 0;
    let locationList: { value: string; label: string }[] = [];
    this.buildingsWithCapacity.map((location: BuildingWithCapacity) => {
      if (location && location.building_type === BuildingType.OFFICE && location.id) {
        if (!locationList.find((loc) => loc.value === `${location.id}`)) {
          locationList.push({
            value: location.id.toString(),
            label: `${location.city}, ${location.building_name} (${
              location.remaining_availibility_percentage
                ? Math.ceil(100 - location.remaining_availibility_percentage)
                : 0
            }${t("%")} ${t("Capacity")})`,
          });
        }
      }
    });
    return locationList.sort((a, b) => (a.label < b.label ? -1 : 1));
  }

  getBuildingCapacity = flow(function* (this: BuildingStore, buildingId: number, date: string) {
    const theBuilding = this.buildingsWithCapacity.find((bld) => bld.id === Number(buildingId));
    if (theBuilding && theBuilding.building_timezone) return theBuilding;
    this.isLoading = true;
    try {
      const url = `/api/building/buildings/${buildingId}/${date}/${date}/capacity/`;
      const res = yield client.get(url);
      this.isLoading = false;
      return res.data[0];
    } catch (err) {
      console.log(err);
      this.error = err;
      this.isLoading = false;
      return this.buildingsWithCapacity;
    }
  });

  getFloor(buildingId: number, floorId: number) {
    return this.buildingsWithCapacity
      .find((bld) => bld.id === buildingId)
      ?.floors?.find((flr) => flr.id === floorId);
  }

  getSpaces = (buildingId: number, floorId: number) => {
    const cachedSpaces = this.buildingsWithCapacity
      .find((bld) => bld.id == buildingId)
      ?.floors?.find((flr) => flr.id == floorId)?.spaces;
    return cachedSpaces && cachedSpaces.length > 0 ? cachedSpaces : null;
  };

  moveSpace = flow(function* (
    this: BuildingStore,
    eSpace: Space,
    newPos: { x: number; y: number }
  ) {
    this.enhancedSpaces = this.enhancedSpaces.map((spc) => {
      if (spc.id == eSpace.id) {
        return { ...spc, corrected_coordinates_x: newPos.x, corrected_coordinates_y: newPos.y };
      } else return { ...spc };
    });
  });

  updateSpace = flow(function* (this: BuildingStore, eSpace: Space, floorId: number) {
    try {
      const data = {
        ...eSpace,
        floor: floorId,
      };
      if (data.id === 0) {
        const res = yield client.post(`/api/spaces/`, data);
        this.floorEnhancedSpaces[floorId] = this.floorEnhancedSpaces[floorId].concat(res.data);
        console.log("api res: ", res);
        return res.data;
      } else {
        const res = yield client.put(`/api/spaces/${eSpace.id}/`, data);
        console.log("api res: ", res.data);
        return res.data;
      }
    } catch (err) {
      console.log(err);
      this.error = err;
      return false;
    }
  });

  deleteSpace = flow(function* (this: BuildingStore, eSpace: Space) {
    if (deskTypeIds.includes(eSpace.space_type?.id || 0)) return false; // reject if not an enhanced space
    if (!this.enhancedSpaces.find((spc) => spc.id == eSpace.id)) return true; // dodgy, but already not in the spaces
    try {
      const res = yield client.delete(`/api/spaces/${eSpace.id}/`);
      console.log("api res: ", res);
      if (`${res.status}` === "204") {
        this.enhancedSpaces = this.enhancedSpaces.filter((spc) => spc.id != eSpace.id);
        return true;
      }
    } catch (err) {
      console.log(err);
      this.error = err;
      return false;
    }
  });

  loadSpaces = flow(function* (
    this: BuildingStore,
    buildingId: number,
    floorId: number,
    date: string,
    forceLoad: boolean = false
  ) {
    // have a look at the cached floor, and see if a space has the correct date
    // if not reload the floor
    const useCache =
      floorId && this.floorSpaces[floorId.toString()]
        ? this.floorSpaces[floorId.toString()].filter((spc) => spc.date === date).length > 0
        : false;
    if (useCache && !forceLoad) {
      this.spaces = [...this.floorSpaces[floorId.toString()]];
      this.enhancedSpaces = this.floorEnhancedSpaces[floorId.toString()]
        ? [...this.floorEnhancedSpaces[floorId.toString()]]
        : [];
      return;
    }
    if (this.isLoadingSpacesForThisFloor(floorId)) return;
    if (!floorId) return;
    if (this.isLoadingSpaces) return;
    this.floorsLoadingSpaces.push(floorId);
    this.isLoadingSpaces = true;
    this.isLoading = true;
    try {
      const res = yield client.get(`/api/spaces/floor/${floorId}/date/${date}/availability/`);
      this.buildingsWithCapacity = this.buildingsWithCapacity.map((bld) => ({
        ...bld,
        floors: bld.floors?.map((flr) => ({
          ...flr,
          spaces: flr.id == floorId ? [...res.data.results] : flr.spaces ? [...flr.spaces] : [],
        })),
      }));
      this.spaces = [...res.data.results];
      const coreSpaces = res.data.results
        .filter((spc) => deskTypeIds.includes(spc.space_type.id || 0))
        .map((spc: any) => {
          const theJson = spc.sv_json;
          delete spc.sv_json;
          const thisSpace =
            theJson && theJson.coordinates
              ? { ...(spc as Space), coordinates: { ...theJson.coordinates } }
              : { ...(spc as Space) };
          this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
          return thisSpace;
        });
      this.floorSpaces[floorId.toString()] = [...coreSpaces];

      const coreEnhancedSpaces = res.data.results
        .filter((spc) => !deskTypeIds.includes(spc.space_type.id || 0))
        .map((spc: any) => {
          const theJson = spc.sv_json;
          delete spc.sv_json;
          const thisSpace =
            theJson && theJson.coordinates
              ? { ...(spc as Space), coordinates: { ...theJson.coordinates } }
              : { ...(spc as Space) };
          return thisSpace;
        });
      this.floorEnhancedSpaces[floorId.toString()] = [...coreEnhancedSpaces];

      this.isLoading = false;
      this.isLoadingSpaces = false;
      this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
    } catch (err) {
      this.isLoading = false;
      this.isLoadingSpaces = false;
      this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
      console.log(err);
      this.error = err;
    }
    this.isLoading = false;
    this.isLoadingSpaces = false;
    this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
  });

  officeAccessLoadSpaces = flow(function* (
    this: BuildingStore,
    buildingId: number,
    floorId: number,
    fromDate: string,
    toDate: string,
    forceLoad: boolean = false
  ) {
    // have a look at the cached floor, and see if a space has the correct date
    // if not reload the floor
    // const useCache =
    //   floorId && this.floorSpaces[floorId.toString()]
    //     ? this.floorSpaces[floorId.toString()].filter((spc) => spc.date === fromDate).length > 0
    //     : false;
    // if (useCache && !forceLoad) {
    //   this.spaces = [...this.floorSpaces[floorId.toString()]];
    //   this.enhancedSpaces = this.floorEnhancedSpaces[floorId.toString()]
    //     ? [...this.floorEnhancedSpaces[floorId.toString()]]
    //     : [];
    //   return;
    // }
    if (this.isLoadingSpacesForThisFloor(floorId)) return;
    if (!floorId) return;
    if (this.isLoadingSpaces) return;
    this.floorsLoadingSpaces.push(floorId);
    this.isLoadingSpaces = true;
    this.isLoading = true;
    try {
      const res = yield client.get(
        `/api/spaces/floor/${floorId}/start_date_time/${fromDate}/end_date_time/${toDate}/availability/`
      );
      this.buildingsWithCapacity = this.buildingsWithCapacity.map((bld) => ({
        ...bld,
        floors: bld.floors?.map((flr) => ({
          ...flr,
          spaces: flr.id == floorId ? [...res.data.results] : flr.spaces ? [...flr.spaces] : [],
        })),
      }));
      this.spaces = [...res.data.results];

      this.spaces = res.data.results
        .filter((spc) => deskTypeIds.includes(spc.space_type.id || 0))
        .map((spc: any) => {
          const theJson = spc.sv_json;

          delete spc.sv_json;
          if (spc.is_booked == false) {
            const thisSpace =
              theJson && theJson.coordinates
                ? { ...(spc as Space), coordinates: { ...theJson.coordinates } }
                : { ...(spc as Space) };
            this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
            return thisSpace;
          }
        });

      const coreSpaces = res.data.results
        .filter((spc) => deskTypeIds.includes(spc.space_type.id || 0))
        .map((spc: any) => {
          const theJson = spc.sv_json;
          delete spc.sv_json;

          if (spc.is_booked == false) {
            const thisSpace =
              theJson && theJson.coordinates
                ? { ...(spc as Space), coordinates: { ...theJson.coordinates } }
                : { ...(spc as Space) };
            this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
            return thisSpace;
          }
        });
      this.floorSpaces[floorId.toString()] = [...coreSpaces];

      const coreEnhancedSpaces = res.data.results
        .filter((spc) => !deskTypeIds.includes(spc.space_type.id || 0))
        .map((spc: any) => {
          const theJson = spc.sv_json;

          delete spc.sv_json;
          if (spc.is_booked == false) {
            const thisSpace =
              theJson && theJson.coordinates
                ? { ...(spc as Space), coordinates: { ...theJson.coordinates } }
                : { ...(spc as Space) };
            return thisSpace;
          }
        });
      this.floorEnhancedSpaces[floorId.toString()] = [...coreEnhancedSpaces];

      this.isLoading = false;
      this.isLoadingSpaces = false;
      this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
    } catch (err) {
      this.isLoading = false;
      this.isLoadingSpaces = false;
      this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
      console.log(err);
      this.error = err;
    }
    this.isLoading = false;
    this.isLoadingSpaces = false;
    this.floorsLoadingSpaces.splice(this.floorsLoadingSpaces.indexOf(floorId), 1);
  });

  primeSpaces = flow(function* (
    this: BuildingStore,
    buildingId: number,
    floorId: number,
    date: string
  ) {
    if (this.getSpaces(buildingId, floorId)) return;
    try {
      const res = yield client.get(
        `/api/spaces/floor/${floorId}/date/${date}/availability/?limit=500`
      );
      //this.floorSpaces[floorId.toString()] = [...res.data.results];
      this.floorSpaces[floorId.toString()] = res.data.results
        .filter((spc) => deskTypeIds.includes(spc.space_type.id || 0))
        .map((spc: any) => {
          const theJson = spc.sv_json;
          delete spc.sv_json;
          const thisSpace = theJson
            ? { ...(spc as Space), coordinates: { ...theJson.coordinates } }
            : { ...(spc as Space) };
          return thisSpace;
        });
      this.floorEnhancedSpaces[floorId.toString()] = [
        ...res.data.results
          .filter((spc) => !deskTypeIds.includes(spc.space_type.id || 0))
          .map((spc: any) => {
            const theJson = spc.sv_json;
            delete spc.sv_json;
            const thisSpace = theJson
              ? { ...(spc as Space), coordinates: { ...theJson.coordinates } }
              : { ...(spc as Space) };
            return thisSpace;
          }),
      ];
    } catch (err) {
      this.error = err;
    }
  });

  updateSpaceCoordinates = flow(function* (
    this: BuildingStore,
    correctedData: {
      id: number;
      corrected_coordinates_x: number;
      corrected_coordinates_y: number;
    }[]
  ) {
    if (correctedData) {
      try {
        const res = yield client.post(`/api/admin/spaces/update_coordinates/`, correctedData);
        console.log("res: ", res);
      } catch (err) {
        this.error = err;
      }
    }
  });

  updateFloorCalibration = flow(function* (
    this: BuildingStore,
    floorId: number,
    calibrationSpecs: CalibrationDetail
  ) {
    if (calibrationSpecs && floorId) {
      try {
        const res = yield client.post(`/api/floor/calibration/`, {
          id: floorId,
          calibration_json: { ...calibrationSpecs },
        });
      } catch (err) {
        this.error = err;
      }
    }
  });

  updateBuildingCalibration = flow(function* (
    this: BuildingStore,
    buildingId: number,
    calibrationSpecs: CalibrationDetail
  ) {
    if (JSON.stringify(calibrationSpecs).length > 10 && buildingId) {
      try {
        const res = yield client.post(`/api/building/calibration/`, {
          id: buildingId,
          calibration_json: { ...calibrationSpecs },
        });
        this.buildingsWithCapacity = this.buildingsWithCapacity.map((bld) => {
          return {
            ...bld,
            calibration_json:
              `${bld.id}` === `${buildingId}`
                ? JSON.stringify(calibrationSpecs)
                : bld.calibration_json,
          };
        });
        console.log(
          "res: ",
          calibrationSpecs,
          toJS(this.buildingsWithCapacity.find((bld) => bld.id === buildingId))
        );
      } catch (err) {
        this.error = err;
      }
    }
  });

  loadSpaceTags = flow(function* (this: BuildingStore, floor_id) {
    const res = yield client.get(`/api/spaces/floor/${floor_id}/tags/`);
    if (res && res.data && res.data.results) {
      return res.data.results;
    }
  });
}
